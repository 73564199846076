import { useState, useEffect, React } from "react";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";

// Default V2 theme
import 'survey-core/defaultV2.min.css';
// Modern theme
//import 'survey-core/modern.min.css';
import "./index.css";
import { json } from "./json";


var storageName = "flashon_loadstate";
//var cookieName = "flashon_form";


function validateMobile1_2(_, options) {
	if (options.name === "mobile") {
		const mobile = options.value;
		if (!mobile.mobile1.trim() == "" || !mobile.mobile2.trim() == "")
			return;

		options.error = "請輸入父親或母親的手提電話。";
	}
}

function loadState(survey) {
	
		//Here should be the code to load the data from your database
		var storageSt = window.localStorage.getItem(storageName) || "";

		var res = {};
		if(storageSt) res = JSON.parse(storageSt);
		
		//Set the loaded data into the survey.
		if(res.currentPageNo) survey.currentPageNo = res.currentPageNo;
		if(res.data) survey.data = res.data;
		
}

function saveState(survey) {
  var res = { currentPageNo: survey.currentPageNo, data: survey.data};
  //Here should be the code to save the data into your database
  window.localStorage.setItem(storageName, JSON.stringify(res));
}

function clearState(survey) {
	window.localStorage.removeItem(storageName);
}


function getOnlineStatus() {
	return typeof navigator !== "undefined" &&
		typeof navigator.onLine === "boolean"
		? navigator.onLine
		: true;
}


function SurveyComponent() {

		let [onlineStatus, setOnlineStatus] = useState(getOnlineStatus());
		let [responseMessage, setResponseMessage] = useState("");

		let isDataSent = false;

		function goOnline() {
			setOnlineStatus(true);
		}
		function goOffline() {
			setOnlineStatus(false);
		}

		useEffect(() => {
			window.addEventListener("online", goOnline);
			window.addEventListener("offline", goOffline);
			return () => {
				window.removeEventListener("online", goOnline);
				window.removeEventListener("offline", goOffline);
			};
		}, []);

		const callEndpoint = (data) => {

			return new Promise((fnSolve) => {

				const xhr = new XMLHttpRequest();
				xhr.onload = xhr.onerror = (e) => {
					try 
					{
						var response = JSON.parse(xhr.responseText);
						if (xhr.status == 200) {
							setResponseMessage(response);
							console.log("Survery sent successfully.");
							fnSolve(true);
	
						} else {	
							// Display the "Error" message (pass a string value to display a custom message)
							alert(JSON.stringify(response));
							console.log("Survery save failed: " + JSON.stringify(response));
							saveState(survey);
							fnSolve(false);
						}
					}
					catch (e)
					{
						alert("儲存失敗，請聯絡職員。( " + JSON.stringify(e) + " )");
						console.log("API call failed: " + JSON.stringify(e));
						saveState(survey);
						fnSolve(false);
					}
				};
	
				xhr.open("POST", process.env.REACT_APP_API_URL, true);
				xhr.setRequestHeader("Content-Type", "application/json; charset=utf-8");
				//xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
				console.log("Start to send the request.");
				xhr.send(JSON.stringify(data));

			});
		};
		

		const survey = new Model(json);
		survey.pagePrevText = "上一頁";
		survey.pageNextText = "下一頁";

		survey.onProcessHtml.add(() => {
			if (survey.hasCookie) {
				survey.deleteCookie();
			}
		});

		loadState(survey);

		survey.onCompleting.add(async (sender, options) => {
			
			options.allowComplete = isDataSent;

			if (!onlineStatus) {
				alert("沒有網絡連線");
				console.log("No Internet connection.");
				saveState(survey);

			}

			if (!isDataSent) {
				callEndpoint(sender.data).then((resp) => {
					console.log("API Endpoint call completed:" + resp);

					if (resp == true) {
						isDataSent = true;
						clearState(survey);
						survey.deleteCookie();
						survey.doComplete();
					}
				});
			}
			
		});

		survey.onComplete.add((sender, options) => {
			console.log("Survey completed.");
			// Display the "Saving..." message (pass a string value to display a custom message)
			options.showSaveInProgress();
			// Display the "Success" message (pass a string value to display a custom message)
			options.showSaveSuccess(JSON.stringify(responseMessage));
		});

		return (<Survey model={survey} />);

}

export default SurveyComponent;